import { Routes } from "@angular/router";
import { AuthenticationGuardService } from "./providers/authentication/route.guard.service";

export const ROUTES: Routes = [
  { path: '', redirectTo: 'account', pathMatch: 'full' },
  { path: 'google/login', loadChildren: () => import('./login/google.login/google.login.callback').then(mod => mod.GoogleLoginCallBackModule) },
  { path: 'microsoft/login', loadChildren: () => import('./login/microsoft.login/microsoft.login.module').then(mod => mod.MicrosoftLoginModule) },
  { path: "teams/login", loadChildren: () => import('./login/microsoft.teams.login/teams.login/teams.login.module').then(mod => mod.TeamsLoginModule) },
  { path: "teams/manual/login", loadChildren: () => import('./login/microsoft.teams.login/teams.manual.login/teams.manual.login.module').then(mod => mod.TeamsManualLoginModule) },
  { path: "teams/context/login", loadChildren: () => import('./login/microsoft.teams.login/teams.context.login/teams.context.login.module').then(mod => mod.TeamsContextLoginModule) },
  { path: "teams/context/callback", loadChildren: () => import('./login/microsoft.teams.login/teams.context.login/teams.context.login.callback.module').then(mod => mod.TeamsContextLoginCallbackModule) },
  {
    path: 'account', children: [
      { path: 'login', loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule), data: { preload: true } },
      { path: 'resetpassword', loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule), data: { preload: true } },
      { path: 'location', loadChildren: () => import('./login/login.module').then(mod => mod.LoginModule), data: { preload: true } },
      { path: 'onboarding', loadChildren: () => import('./login/onboarding').then(mod => mod.UserOnboardingLazyModule), data: { preload: true } },
      { path: '', loadChildren: () => import('./common/main.layout/main.layout.module').then(mod => mod.MainLayoutModule), data: { preload: true } },
      { path: 'security', loadChildren: () => import('./security/security.module').then(mod => mod.SecurityModule), data: { preload: true } },
      { path: 'manual/report/download', canActivateChild: [AuthenticationGuardService], children: [{ path: ':actionId', loadComponent: () => import('./admin/corp/bulk-invite-report-download/bulk-invite-report-download.component').then(mod => mod.BulkInviteReportDownloadComponent) }] },
    ]
  },
  { path: 'admin', children: [{ path: '**', loadChildren: () => import('./redirect/redirect.module').then(mod => mod.RedirectModule) }] },
  { path: 'token-verify', children: [{ path: '**', loadChildren: () => import('./token.verify').then(mod => mod.TokenVerifyModule) }] },
  { path: 'redirect', children: [{ path: '**', loadChildren: () => import('./redirect/redirect.module').then(mod => mod.RedirectModule) }] },
  { path: 'user', children: [{ path: '**', loadChildren: () => import('./redirect/redirect.module').then(mod => mod.RedirectModule) }] },
  { path: 'login', children: [{ path: '**', loadChildren: () => import('./redirect/redirect.module').then(mod => mod.RedirectModule) }] },
  { path: 'report/download', canActivateChild: [AuthenticationGuardService], children: [{ path: ':type/:pageType/:actionId', loadChildren: () => import('./report.download').then(mod => mod.ReportDownloadModule) }] },
  { path: 'page-not-found', loadChildren: () => import('./page.not.exist/page.not.exists.module').then(mod => mod.PageNotExistsModule) },
  { path: 'access-denied', loadChildren: () => import('./page.not.exist/page.not.exists.module').then(mod => mod.PageNotExistsModule) },
  { path: 'giftcard/:referenceId/status/:identifier', loadComponent: () => import('./admin/Static/paymarked-status-page/paymarked-status-page.component').then(mod => mod.PaymarkedStatusPageComponent) },
  { path: '**', loadChildren: () => import('./redirect/redirect.module').then(mod => mod.RedirectModule) },
]
